<template>
  <div class="diff_list_top">
    <div class="right_title">效果对比</div>
    <div v-if="detectData" class="detect_data">
      Frame: {{ detectData.frame }}&nbsp;&nbsp;&nbsp;&nbsp;Error:{{
        detectData.error
      }}
    </div>
    <a-button @click="openPic" class="right_btn">查看差异</a-button>
  </div>
  <div class="diff_list_body" :id="'shader_' + idName + '_diff_pic_scroll'">
    <a-carousel
      arrows
      v-if="carouselShow"
      class="screen_pic_list"
      :afterChange="carouselChange"
      :dots="false"
    >
      <template #prevArrow>
        <div class="custom-slick-arrow" v-if="!detectData">
          <svg-icon icon-class="chevron-left" class="icon" />
        </div>
      </template>
      <template #nextArrow>
        <div class="custom-slick-arrow" v-if="!detectData">
          <svg-icon icon-class="chevron-right" class="icon" />
        </div>
      </template>
      <div v-for="(item, index) in pics" :key="index">
        <div class="pic_con">
          <a-image
            :width="480"
            :height="270"
            style="background-color: #3c3c3c"
            :preview="false"
            :src="item.reference.url || ''"
            :fallback="imgSvg"
          />
          <div class="pic_tip">优化前</div>
          <div class="pic_diff" @click.stop="showDiff" :data-no="index">
            <div class="pic_diff_tip">
              <svg-icon icon-class="contrast" class="diff_icon" />
              效果对比
            </div>
          </div>
        </div>
        <div class="pic_space"></div>
        <div class="pic_con">
          <a-image
            :width="480"
            :height="270"
            :preview="false"
            style="background-color: #3c3c3c"
            :src="item.variant.url || ''"
            :fallback="imgSvg"
          />
          <div class="pic_tip">优化后</div>
        </div>
      </div>
    </a-carousel>
    <div class="pic_count" v-if="!detectData">
      {{ currentPic + 1 }}/{{ pics.length }}
    </div>
    <div class="screen_contrast" :id="'rays_tuner_' + idName + '_pic_contrast'">
      <a-image
        :width="2"
        :height="2"
        :src="contrastPic"
        :preview="{ onVisibleChange: onVisibleChange }"
      />
    </div>
    <div class="contrast_legend" v-if="legendVisible">
      <a-tooltip placement="left" overlayClassName="rays_tuner_diff_pic_legend">
        <template #title>{{ maxLegend }}</template>
        <input
          class="legend_max"
          type="text"
          :value="maxLegend"
          :title="maxLegend"
          @keyup.enter="inputMaxLegend"
        />
      </a-tooltip>
      <div class="legend_pic"></div>
      0
    </div>
  </div>
  <diff-pic-view :picData="pics[currentPic]" v-model:show="diffShow" />
</template>
<script>
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
  toRefs,
  nextTick,
} from 'vue';
import DiffPicView from './DiffPicView.vue';
import PerfectScrollbar from 'perfect-scrollbar';
import { message } from 'ant-design-vue';

export default defineComponent({
  name: 'DiffPicList',
  components: {
    DiffPicView,
  },
  props: {
    idName: {
      type: String,
      required: true,
    },
    detectData: {
      type: Object,
    },
    /**
         * {
                reference: {},//原始图片
                variant: {}  //变种图片
            }
            */
    pics: {
      type: Array,
    },
  },
  emits: ['openComparePic', 'boundChange'],
  setup(props, context) {
    let screenPicDom = null;
    let screenPicPs = null;
    let imgSvg =
      'data:image/svg+xml;utf8,<svg width="240" height="136" viewBox="-38 -38 100 100" fill="gray" ' +
      'xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" ' +
      'd="M19 17.834C19 18.478 18.552 19 18 19H6L13.566 12.183C13.812 11.938 14.258 11.939 14.499 ' +
      '12.182L19 16.66V17.834ZM8 7C8.828 7 9.5 7.672 9.5 8.5C9.5 9.328 8.828 10 8 10C7.172 10 6.5 9.328 ' +
      '6.5 8.5C6.5 7.672 7.172 7 8 7ZM18 3H6C4.346 3 3 4.346 3 6V18C3 19.654 4.346 21 6 21H18C19.654 21 ' +
      '21 19.654 21 18V6C21 4.346 19.654 3 18 3Z"/></svg>';

    const { pics } = toRefs(props);

    watch(pics, (newpics) => {
      console.log('watch pics', newpics);
      currentPic.value = 0;
      carouselShow.value = false;
      nextTick(() => {
        carouselShow.value = true;
      });
    });

    const carouselShow = ref(true);

    const contrastPic = ref('');
    const diffShow = ref(false);
    const currentPic = ref(0);

    const openPic = () => {
      // console.log('openPic');
      maxLegend.value = 0.1;
      context.emit('openComparePic', currentPic.value, maxLegend.value);
    };
    const carouselChange = (val) => {
      // console.log('carouselChange', val);
      currentPic.value = val;
    };

    const showDiff = () => {
      // let _pic = pics.value[currentPic.value];
      // console.log('showDiff', currentPic.value);
      diffShow.value = true;
    };

    const clickContrast = (contrast) => {
      contrastPic.value = contrast;
      nextTick(() => {
        const refdom = document.querySelector(
          '#rays_tuner_' + props.idName + '_pic_contrast img'
        );
        refdom.click();
        // console.log('refdom', refdom);
      });
    };

    const legendVisible = ref(false);
    const onVisibleChange = (visible) => {
      // console.log('onVisibleChange', visible);
      legendVisible.value = visible;
    };

    const maxLegend = ref(0.1);
    const inputMaxLegend = (event) => {
      const _val = event.target.value;
      maxLegend.value = event.target.value;
      console.log(
        'inputMaxLegend',
        event,
        _val,
        parseFloat(_val) == _val,
        parseFloat(_val)
      );
      if (
        (parseFloat(_val) == _val &&
          (parseFloat(_val) < 0 || parseFloat(_val) > 1)) ||
        parseFloat(_val) != _val ||
        _val === ''
      ) {
        message.warning('请输入0-1之间的数字！');
        return;
      }
      context.emit('boundChange', currentPic.value, parseFloat(_val));
    };

    onMounted(() => {
      screenPicDom = document.querySelector(
        '#shader_' + props.idName + '_diff_pic_scroll'
      );
      screenPicPs = new PerfectScrollbar(screenPicDom);
    });
    onBeforeUnmount(() => {
      if (screenPicPs) {
        screenPicPs.destroy();
        screenPicPs = null;
      }
      screenPicDom = null;
    });
    return {
      imgSvg,
      carouselShow,
      contrastPic,
      currentPic,
      diffShow,
      legendVisible,
      maxLegend,
      inputMaxLegend,
      openPic,
      carouselChange,
      clickContrast,
      onVisibleChange,
      showDiff,
    };
  },
});
</script>
<style lang="scss" scoped>
.diff_list_top {
  height: 32px;
  .right_title {
    float: left;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    text-align: LEFT;
    color: #222222;
    line-height: 24px;
  }
  .detect_data {
    display: inline-block;
    margin-top: 1px;
    margin-left: 32px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #8c8c8c;
  }
  .right_btn {
    float: right;
    background: rgba(96, 75, 220, 0.1);
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #604bdc;
    line-height: 22px;
  }
}
.diff_list_body {
  position: relative;
  height: calc(100% - 76px);
  margin-top: 44px;
  .pic_count {
    position: fixed;
    right: 199px;
    bottom: 20px;
    width: 132px;
    height: 24px;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    text-align: center;
    color: #3c3c3c;
    line-height: 24px;
    background-color: rgba(255, 255, 255, 0.4);
  }
  .screen_contrast {
    position: fixed;
    right: -50px;
  }
  .screen_pic_list {
    line-height: 0;
    .icon {
      width: 24px;
      height: 24px;
      color: #aaa;
    }
    .pic_space {
      height: 48px;
    }
    .pic_con {
      position: relative;
      overflow: hidden;
      border-radius: 2px;
      .pic_tip {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 74px;
        height: 26px;
        border-radius: 2px;
        background: rgba(0, 0, 0, 0.6);
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Bold;
        font-weight: 500;
        text-align: center;
        color: #fff;
        line-height: 26px;
      }
      .pic_diff {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background: rgba(0, 0, 0, 0.5);
        cursor: pointer;
        display: none;
        .pic_diff_tip {
          height: 22px;
          margin-top: 124px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Bold;
          font-weight: 500;
          text-align: center;
          color: #fff;
          line-height: 22px;
          .diff_icon {
            width: 16px;
            height: 16px;
            vertical-align: middle;
          }
        }
      }
      &:hover .pic_diff {
        display: block;
      }
    }
    .slick-prev {
      position: fixed;
      top: auto;
      right: 306px;
      left: auto;
      bottom: 20px;
    }
    .slick-next {
      position: fixed;
      top: auto;
      right: 200px;
      bottom: 20px;
    }
    & :deep(.slick-slide) {
      text-align: center;
      height: 100%;
      overflow: hidden;
    }

    & :deep(.slick-arrow.custom-slick-arrow) {
      width: 24px;
      height: 24px;
      // color: #fff;
      z-index: 100;
    }
    & :deep(.custom-slick-arrow:before) {
      display: none;
    }
    // &  :deep(.custom-slick-arrow:hover) {
    //     opacity: 0.8;
    // }
    & :deep(.slick-slide h3) {
      color: #fff;
    }
  }
  .contrast_legend {
    position: fixed;
    right: 0;
    top: 46px;
    width: 22px;
    height: 300px;
    z-index: 2010;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.1);
    text-align: center;
    font-weight: 500;
    .legend_max {
      width: 22px;
      border: 0;
      outline: none;
      background-color: rgba(0, 0, 0, 0.1);
    }
    .legend_pic {
      height: 256px;
      background: url('~@/assets/legend.png');
    }
  }
}
</style>
<style lang="scss">
.rays_tuner_diff_pic_legend {
  z-index: 2011;
}
</style>
