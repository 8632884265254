
import { defineComponent, ref, watch, reactive, onBeforeMount } from 'vue';
import { message } from 'ant-design-vue';

export default defineComponent({
  name: 'CodeView',
  props: {
    codeData: {
      type: Object,
      require: true
    },
    compClass: {
      type: String,
      require: true,
    },
  },
  setup(props) {
    // console.log('CodeView', props.codeData,props.compClass);
    const activeKey = ref('1');
    const copy = reactive({key: props.compClass, value: ''});
    const copyResult = (res)=>{
      // console.log('copyResult',res, props.compClass);
      if(res) {
        message.success('复制成功');
      } else {
        message.error('复制失败（浏览器不兼容）');
      }
    }
    const noTotype = ['', 'vs', 'fs'];
    watch([()=>props.codeData, activeKey], ()=>{
      // console.log('watch CodeView', props.compClass);
      copy.value = (props.codeData as object)[noTotype[activeKey.value]];
    });

    onBeforeMount(()=>{
      // console.log('CodeView onBeforeMount', props.compClass,props.codeData);
      copy.value = (props.codeData as object)[noTotype[activeKey.value]];
    })

    
    // 
    // const copy = () => {
    //   const input = document.createElement('input');
    //   // input.setAttribute('readonly', 'readonly');
    //   input.setAttribute('value', props.codeData[noTotype[activeKey.value]]);
    //   document.body.appendChild(input);
    //   input.select()//setSelectionRange(0, 9999);
    //   if (document.execCommand('copy')) {
    //     document.execCommand('copy',true);
    //     console.log(input,props.codeData[noTotype[activeKey.value]]);
    //     message.success('复制成功');
    //   } else {
    //     message.error('复制失败（浏览器不兼容）');
    //   }
    //   document.body.removeChild(input);
    // };
    return {
      activeKey,
      copy,
      copyResult
      // copy,
    };
  },
});
