<template>
  <div class="rays_tuner_pic_viewer" :style="showStyle">
    <div class="viewer_left_con">
      <img
        :src="picData.reference.url"
        id="rays_tuner_pic_viewer_left"
        style="display: none"
      />
    </div>

    <div class="viewer_right_con">
      <img
        :src="picData.variant.url"
        id="rays_tuner_pic_viewer_right"
        style="display: none"
      />
    </div>
    <svg-icon icon-class="close" class="close_btn" @click="close" />
  </div>
</template>

<script>
import { defineComponent, onMounted, onBeforeUnmount, watch, ref } from 'vue';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';

export default defineComponent({
  name: 'DiffPicView',
  props: {
    picData: {
      type: Object,
      default() {
        return {
          reference: {
            // url:'http://192.168.50.246:8099/api/sopt/simplifier/getHighlightByFilename?filename=reference_6733_240x136_cmp.png&pat=com.tencent.tmgp.pubgmhd.o2.pat&user=admin@shader.com&pid=67'
          },
          variant: {
            // url:'http://192.168.50.246:8099/api/sopt/simplifier/getHighlightByFilename?filename=variant_6733_240x136_cmp.png&pat=com.tencent.tmgp.pubgmhd.o2.pat&user=admin@shader.com&pid=67'
          },
        };
      },
      require: true,
    },
    show: {
      type: Boolean,
      default: false,
      require: true,
    },
  },
  emits: ['update:show'],
  setup(props, context) {
    // console.log('DiffPicView', props.picData);
    const showStyle = ref('');
    let viewerDomL = null;
    let viewerL = null;
    let viewerDomR = null;
    let viewerR = null;
    let focusL = false;
    let focusR = false;
    watch(
      () => props.show,
      (show) => {
        // console.log('watch show', show);
        if (show) {
          showStyle.value = 'display: block;';
          initPic();
        } else {
          showStyle.value = '';
        }
      }
    );

    const close = () => {
      showStyle.value = '';
      context.emit('update:show', false);
    };

    const mouseoverFuns = [
      function () {
        // console.log('pointerdownL');
        focusL = true;
        focusR = false;
      },
      function () {
        // console.log('pointerdownR');
        focusL = false;
        focusR = true;
      },
    ];

    const initPic = () => {
      if (viewerL) {
        if (viewerL.image) {
          viewerL.image.removeEventListener('mouseover', mouseoverFuns[0]);
        }
        viewerL.destroy();
        viewerL = null;
      }
      if (viewerR) {
        if (viewerR.image) {
          viewerR.image.removeEventListener('mouseover', mouseoverFuns[1]);
        }
        viewerR.destroy();
        viewerR = null;
      }
      viewerL = new Viewer(viewerDomL, {
        inline: true,
        viewed() {
          // viewerL.zoomTo(1);
          // console.log('viewed L', viewerL);
          viewerL.image.addEventListener('mouseover', mouseoverFuns[0]);
        },
        toolbar: false,
        title: false,
        backdrop: false,
        button: false,
        navbar: false,
        moved: (event) => {
          // console.log('movedl', event);
          if (focusL) {
            viewerR.moveTo(event.detail.x, event.detail.y);
          }
        },
        zoomed: (event) => {
          // console.log('scaledl',event);
          if (focusL) {
            const _detail = event.detail;
            viewerR.zoomTo(_detail.ratio);
            // viewerR.moveTo(_detail.originalEvent.screenX, _detail.originalEvent.screenY);
          }
        },
      });

      viewerR = new Viewer(viewerDomR, {
        inline: true,
        viewed() {
          // viewerR.zoomTo(1);
          viewerR.image.addEventListener('mouseover', mouseoverFuns[1]);
        },
        toolbar: false,
        title: false,
        backdrop: false,
        button: false,
        navbar: false,
        moved: (event) => {
          // console.log('movedR', event);
          if (focusR) {
            viewerL.moveTo(event.detail.x, event.detail.y);
          }
        },
        zoomed: (event) => {
          // console.log('scaledR',event);
          if (focusR) {
            const _detail = event.detail;
            viewerL.zoomTo(_detail.ratio);
            // viewerL.moveTo(_detail.originalEvent.screenX, _detail.originalEvent.screenY);
          }
        },
      });
    };

    onMounted(() => {
      viewerDomL = document.getElementById('rays_tuner_pic_viewer_left');
      // console.log('viewerDomL', viewerDomL);
      viewerDomR = document.getElementById('rays_tuner_pic_viewer_right');
      // console.log('viewerDomL', viewerDomR);
      initPic();
    });

    onBeforeUnmount(() => {
      if (viewerL) {
        if (viewerL.image) {
          viewerL.image.removeEventListener('mouseover', mouseoverFuns[0]);
        }
        viewerL.destroy();
        viewerL = null;
      }
      viewerDomL = null;

      if (viewerR) {
        if (viewerR.image) {
          viewerR.image.removeEventListener('mouseover', mouseoverFuns[1]);
        }
        viewerR.destroy();
        viewerR = null;
      }
      viewerDomR = null;
    });

    return {
      showStyle,
      close,
    };
  },
});
</script>
<style lang="scss" scoped>
.rays_tuner_pic_viewer {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  height: 100%;
  width: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  .viewer_left_con {
    width: 50%;
    height: 100%;
    float: left;
    border-right: 1px solid #000;
  }
  .viewer_right_con {
    width: 50%;
    height: 100%;
    float: right;
  }
  .close_btn {
    position: absolute;
    right: 28px;
    top: 28px;
    width: 24px;
    height: 24px;
    color: #fff;
    cursor: pointer;
  }
}
</style>
