<template>
  <a-layout class="ant-layout-has-sider rays-tuner-screentool">
    <a-layout-sider class="slider" width="288" theme="light">
      <screen-task-list
        stitle="筛选任务列表"
        list-id="shader_screentool_task_scroll"
      />
    </a-layout-sider>
    <a-layout-content class="screen_body_empty" v-show="pageStat == 0">
      <div class="empty-con">
        <div class="empty-icon"></div>
        <div class="empty-tip">暂无任务，请在完成的简化任务中筛选</div>
      </div>
    </a-layout-content>
    <a-layout-content class="screen_body" v-show="pageStat == 1">
      <div class="body_header">
        <div class="header_top">
          <div class="top_title">
            变体shader{{ curScreenTask.params.var_shader_name }}
          </div>
          <a-button @click="downloadCode" class="top_btn">下载代码</a-button>
          <a-button @click="downloadPkg" class="top_btn">下载打包数据</a-button>
        </div>
        <div class="header_bottom">
          <a-tooltip placement="bottomLeft" :destroyTooltipOnHide="true">
            <template #title>
              <span>{{ curScreenTask.params.time }}</span>
            </template>
            <div class="header_time">Time: {{ curScreenTask.params.time }}</div>
          </a-tooltip>
          <a-tooltip placement="bottomLeft" :destroyTooltipOnHide="true">
            <template #title>
              <span>{{ curScreenTask.params.error }}</span>
            </template>
            <div class="header_error">
              Error: {{ curScreenTask.params.error }}
            </div>
          </a-tooltip>
          <div class="header_simplify_name">
            简化任务:
            <div class="simplify_id">
              {{ curScreenTask.params.simpl_task_id }}
            </div>
          </div>
        </div>
      </div>
      <div class="body_code_con">
        <code-view :codeData="screenCode" compClass="screen_code" />
      </div>
    </a-layout-content>
    <a-layout-sider
      class="slider_right"
      width="528"
      theme="light"
      v-show="pageStat == 1"
    >
      <diff-pic-view
        ref="diffPicRef"
        :pics="pics"
        @openComparePic="openPic"
        @boundChange="openPic"
        id-name="screen_tool"
      />
    </a-layout-sider>
  </a-layout>
</template>

<script>
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  computed,
  watch,
} from 'vue';

import ScreenTaskList from './ScreenTaskList.vue';
import store from '@/vuex/store';
import { message } from 'ant-design-vue';
import DiffPicView from '@/components/DiffPicView';
import CodeView from '@/components/CodeView';
import api from '@/api/shader-tools';
import { getBaseUrl } from '@/api/service';
import { convertRes2Blob } from '@/utils/download'
const engineurl = getBaseUrl('engine');

export default defineComponent({
  name: 'ScreenTool',
  components: {
    ScreenTaskList,
    DiffPicView,
    CodeView,
  },
  setup() {
    const $_user = store.state.user.userinfo;
    const $_email = $_user.email;
    const $_pat = store.state.shaderTool.currentPat;

    const curShader = computed(() => store.state.shaderTool.currentShader);

    // watch(()=>curShader.value.shader_id, () => {
    //     console.log('watch curShader');

    // })
    const screenCode = ref({ fs: '', vs: '' });
    const curScreenTask = computed(
      () => store.state.shaderTool.currentShaderScreenTask || { params: {} }
    );
    watch(
      [
        () => curScreenTask.value.task_number,
        () => curScreenTask.value.params.var_shader_name,
      ],
      () => {
        // console.log('watch curScreenTask');
        getReplayTestList();
        getVariantShaderCode();
      }
    );

    const pageStat = computed(
      () => store.state.shaderTool.shaderScreen.pageStat || 0
    );
    // watch(() => pageStat , () => {
    //     console.log('watch pageStat', pageStat.value);

    // })

    /**
             * {
                    reference: {},//原始图片
                    variant: {}  //变种图片
                }
             */
    const pics = ref([]);

    const getReplayTestList = async () => {
      // picStat.value = 1;
      const _taskId = curScreenTask.value.params.simpl_task_id;
      const _pid = curShader.value.shader_id;
      const _variant = curScreenTask.value.params.var_shader_name;
      if (!_taskId || !_variant) {
        return;
      }
      const params = {
        pat: $_pat.pat_name,
        user: $_email,
        // user_id: $_user.uid,
        simpl_task_id: _taskId,
        pid: _pid,
        variant: _variant,
      };
      const res = await api.getReplayTestList(params);
      if (!res) return;
      if (res.code != 0) {
        message.error('正在生成获取筛选截图:' + res.detail);
        return;
      }
      let _pics = [];
      for (let i = 0; i < res.reference.length; i++) {
        const _fileR = res.reference[i];
        const _fileV = res.variant[i];
        _pics.push({
          id: i,
          reference: {
            file: _fileR,
            url:
              engineurl +
              '/api/sopt/simplifier/getReplayTestByFilename?filename=' +
              _fileR +
              '&pat=' +
              $_pat.pat_name +
              '&user=' +
              $_email +
              '&pid=' +
              _pid +
              '&simpl_task_id=' +
              _taskId +
              '&variant=' +
              _variant,
          },
          variant: {
            file: _fileV,
            url:
              engineurl +
              '/api/sopt/simplifier/getReplayTestByFilename?filename=' +
              _fileV +
              '&pat=' +
              $_pat.pat_name +
              '&user=' +
              $_email +
              '&pid=' +
              _pid +
              '&simpl_task_id=' +
              _taskId +
              '&variant=' +
              _variant,
          },
        });
      }
      pics.value = _pics;
    };

    const getVariantShaderCode = async () => {
      const _taskId = curScreenTask.value.params.simpl_task_id;
      const _pid = curShader.value.shader_id;
      const _variant = curScreenTask.value.params.var_shader_name;
      if (!_taskId || !_variant) {
        return;
      }
      const params = {
        user: $_email,
        pat: $_pat.pat_name,
        pid: _pid,
        simpl_task_id: _taskId,
        variant: _variant,
      };
      const res = await api.getVariantShaderCode(params);
      if (!res) return;
      if (res.code == 0) {
        screenCode.value = {
          fs: res.fragment || '',
          vs: res.vertex || '',
        };
      } else {
        message.error('获取筛选变体shader代码失败：', res.detail);
      }
    };
    const diffPicRef = ref(null);
    const getComparePicture = (currentPic, bound) => {
      const _taskId = curScreenTask.value.params.simpl_task_id;
      const _pid = curShader.value.shader_id;
      const _variant = curScreenTask.value.params.var_shader_name;
      const _pic = pics.value[currentPic];
      const _refer = _pic.reference.file;
      const _vari = _pic.variant.file;
      const _contrastPic =
        engineurl +
        '/api/sopt/simplifier/getComparePictureByFilename?' +
        'pat=' +
        $_pat.pat_name +
        '&user=' +
        $_email +
        '&pid=' +
        _pid +
        '&simpl_task_id=' +
        _taskId +
        '&variant=' +
        _variant +
        '&reference_name=' +
        _refer +
        '&variant_name=' +
        _vari +
        '&bound=' +
        bound;
      diffPicRef.value.clickContrast(_contrastPic);
    };

    

    const downloadFile = async (type) => {
      const _taskId = curScreenTask.value.params.simpl_task_id;
      const _pid = curShader.value.shader_id;
      const _variant = curScreenTask.value.params.var_shader_name;
      if (!_taskId || !_variant) {
        return;
      }
      const params = {
        type: type || 0,
        user: $_email,
        pat: $_pat.pat_name,
        shader_id: _pid,
        task_id: _taskId,
        variant: _variant,
      };
      const res = await api.downloadFile(params, { returnRes: true, responseType:'blob' });
      if (!res) return;
      if (res.statusText == 'OK') {
        convertRes2Blob(res);
      } else {
        message.error('获取下载数据失败');
      }
    };

    const downloadCode = () => {
      // console.log('downloadCode');
      downloadFile(0);
    };

    const downloadPkg = () => {
      // console.log('downloadPkg');
      downloadFile(1);
    };

    const openPic = (currentPic, bound) => {
      console.log('openPicSCreen', currentPic, bound);
      getComparePicture(currentPic, bound);
    };

    onMounted(() => {
      // console.log('ScreenTool onMounted',process.env.NODE_ENV);
      getReplayTestList();
      getVariantShaderCode();
    });
    onBeforeUnmount(() => {});

    return {
      curScreenTask,
      screenCode,
      pics,
      pageStat,
      diffPicRef,
      downloadCode,
      downloadPkg,
      openPic,
    };
  },
});
</script>

<style scoped lang="scss">
.slider {
  background-color: #fff;
  border-right: 1px solid #d8d8d8;
}

.screen_body {
  background-color: #fff;
  padding: 24px 40px 32px 40px;
  .body_header {
    height: 88px;
    .header_top {
      height: 36px;
      .top_title {
        height: 36px;
        float: left;
        color: #222222;
        font-size: 16px;
        line-height: 32px;
        font-family: PingFang SC, PingFang SC-Bold;
        font-weight: 700;
        text-align: LEFT;
      }
      .top_btn {
        float: right;
        margin-left: 20px;
        border-radius: 4px;
        color: #8c8c8c;
        font-size: 14px;
        background: #fafafa;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
      }
    }
    .header_bottom {
      height: 32px;
      margin-top: 4px;
      padding-top: 5px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: LEFT;
      color: #8c8c8c;
      line-height: 22px;
      .header_time {
        display: inline-block;
        width: 143px;
        padding-right: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .header_error {
        display: inline-block;
        width: 135px;
        padding-right: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .header_simplify_name {
        display: inline-block;
        overflow: hidden;
        min-width: 261px;
        .simplify_id {
          display: inline;
          color: #604bdc;
        }
      }
    }
  }
  .body_code_con {
    height: calc(100% - 88px);
    border-radius: 4px;
    // border: 1px solid #d8d8d8;
  }
}

.screen_body_empty {
  position: relative;
  .empty-con {
    position: absolute;
    left: calc(50% - 90px);
    top: calc(50% - 80px);
    width: 240px;
    height: 182px;
    font-weight: 500;
    text-align: CENTER;
    .empty-icon {
      width: 79px;
      height: 79px;
      margin-left: 80px;
      background: url('~@/assets/Inbox.png') no-repeat;
      border: 1px dashed rgba(0, 0, 0, 0.2);
    }
    .empty-tip {
      margin-top: 8px;
      color: #666666;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Medium;
    }
  }
}

.slider_right {
  border-left: 1px solid #d8d8d8;
  padding: 20px 24px 20px 24px;
}
</style>
